.jira-collector-container {
  position: fixed;
  bottom: 50%;
  right: -55px;
  border-radius: 0;
  /* transform: translateY(-50%); */
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  /* padding: 10px; */
  /* background-color: #000; Adjust as needed */
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.jira-collector-button {
  display: block;
  width: 150px; /* Adjust as needed */
  padding: 10px;
  background-color: #eeeeee; /* Adjust as needed */
  color: #8d8383; /* Adjust as needed */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}
