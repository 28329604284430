*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "poppins", "roboto", "roboto-condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 247, 249);
}

/* perfect scrollbar visible  */
.ps .ps__rail-x {
  opacity: 0.6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-picker-dropdown {
  z-index: 1500;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tabulator {
  overflow: scroll !important;
  width: auto !important;
  max-width: none !important;
  font-size:11px !important;
  padding-bottom: 0;
}
.tabulator-row .tabulator-cell {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 2px;
  vertical-align: middle;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0.1px dashed rgb(209, 205, 205);
}

/* .tabulator-col:first-child {
  width: 60px !important;
} */

/* .tabulator-col {
  width: 200px !important;
}

.tabulator-cell:first-child {
  width: 60px !important;
}

.tabulator-cell {
  width: 200px !important;
} */

/* .tabulator-row{
  position: relative;
  box-sizing: border-box;
  min-height: 30px;
  width: 150px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  float: l; */
/* } */

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./assets/fonsts/roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url(./assets/fonsts/formFonts/Lato/Lato-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Lato-Italic";
  src: local("Lato-Italic"),
    url(./assets/fonsts/formFonts/Lato/Lato-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"),
    url(./assets/fonsts/formFonts/Lato/Lato-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Lato-Thin";
  src: local("Lato-Thin"),
    url(./assets/fonsts/formFonts/Lato/Lato-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(./assets/fonsts/formFonts/Montserrat/Montserrat-Bold.ttf)
      format("truetype");
}
@font-face {
  font-family: "Montserrat-Italic";
  src: local("Montserrat-Italic"),
    url(./assets/fonsts/formFonts/Montserrat/Montserrat-Italic.ttf)
      format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(./assets/fonsts/formFonts/Montserrat/Montserrat-Regular.ttf)
      format("truetype");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat-Thin"),
    url(./assets/fonsts/formFonts/Montserrat/Montserrat-Thin.ttf)
      format("truetype");
}
@font-face {
  font-family: "NunitoSans-Bold";
  src: local("NunitoSans-Bold"),
    url(./assets/fonsts/formFonts/NunitoSans/NunitoSans-Bold.ttf)
      format("truetype");
}
@font-face {
  font-family: "NunitoSans-Italic";
  src: local("NunitoSans-Italic"),
    url(./assets/fonsts/formFonts/NunitoSans/NunitoSans-Italic.ttf)
      format("truetype");
}
@font-face {
  font-family: "NunitoSans-Regular";
  src: local("NunitoSans-Regular"),
    url(./assets/fonsts/formFonts/NunitoSans/NunitoSans-Regular.ttf)
      format("truetype");
}
@font-face {
  font-family: "NunitoSans-Light";
  src: local("NunitoSans-Light"),
    url(./assets/fonsts/formFonts/NunitoSans/NunitoSans-Light.ttf)
      format("truetype");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
    url(./assets/fonsts/formFonts/OpenSans/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans-Italic";
  src: local("OpenSans-Italic"),
    url(./assets/fonsts/formFonts/OpenSans/OpenSans-Italic.ttf)
      format("truetype");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url(./assets/fonsts/formFonts/OpenSans/OpenSans-Regular.ttf)
      format("truetype");
}
@font-face {
  font-family: "OpenSans-Medium";
  src: local("OpenSans-Medium"),
    url(./assets/fonsts/formFonts/OpenSans/OpenSans-Medium.ttf)
      format("truetype");
}
@font-face {
  font-family: "Oswald-Bold";
  src: local("Oswald-Bold"),
    url(./assets/fonsts/formFonts/Oswald/Oswald-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald-Light";
  src: local("Oswald-Light"),
    url(./assets/fonsts/formFonts/Oswald/Oswald-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald-Regular";
  src: local("Oswald-Regular"),
    url(./assets/fonsts/formFonts/Oswald/Oswald-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald-Medium";
  src: local("Oswald-Medium"),
    url(./assets/fonsts/formFonts/Oswald/Oswald-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt-Bold";
  src: local("Prompt-Bold"),
    url(./assets/fonsts/formFonts/Prompt/Prompt-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt-Light";
  src: local("Prompt-Light"),
    url(./assets/fonsts/formFonts/Prompt/Prompt-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt-Medium";
  src: local("Prompt-Medium"),
    url(./assets/fonsts/formFonts/Prompt/Prompt-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt-Thin";
  src: local("Prompt-Thin"),
    url(./assets/fonsts/formFonts/Prompt/Prompt-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "PTSans-Bold";
  src: local("PTSans-Bold"),
    url(./assets/fonsts/formFonts/PTSans/PTSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PTSans-Italic";
  src: local("PTSans-Italic"),
    url(./assets/fonsts/formFonts/PTSans/PTSans-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "PTSans-Regular";
  src: local("PTSans-Regular"),
    url(./assets/fonsts/formFonts/PTSans/PTSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoSlab-Bold";
  src: local("RobotoSlab-Bold"),
    url(./assets/fonsts/formFonts/RobotoSlab/RobotoSlab-Bold.ttf)
      format("truetype");
}
@font-face {
  font-family: "RobotoSlab-Medium";
  src: local("RobotoSlab-Medium"),
    url(./assets/fonsts/formFonts/RobotoSlab/RobotoSlab-Medium.ttf)
      format("truetype");
}
@font-face {
  font-family: "RobotoSlab-Regular";
  src: local("RobotoSlab-Regular"),
    url(./assets/fonsts/formFonts/RobotoSlab/RobotoSlab-Regular.ttf)
      format("truetype");
}
@font-face {
  font-family: "WorkSans-Bold";
  src: local("WorkSans-Bold"),
    url(./assets/fonsts/formFonts/WorkSans/WorkSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "WorkSans-Medium";
  src: local("WorkSans-Medium"),
    url(./assets/fonsts/formFonts/WorkSans/WorkSans-Medium.ttf)
      format("truetype");
}
@font-face {
  font-family: "WorkSans-SemiBold";
  src: local("WorkSans-SemiBold"),
    url(./assets/fonsts/formFonts/WorkSans/WorkSans-SemiBold.ttf)
      format("truetype");
}
@font-face {
  font-family: "WorkSans-Thin";
  src: local("WorkSans-Thin"),
    url(./assets/fonsts/formFonts/WorkSans/WorkSans-Thin.ttf) format("truetype");
}
